import React, { useState } from "react";
import axios from "axios";

const Attendance = () => {
    const [username, setUsername] = useState("");
    const [code, setCode] = useState("");
    const [status, setStatus] = useState("");

    const onClickAttendance = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("username", username);
        formData.append("code", code);

        axios.post("http://tolelom.xyz:8080/attendance", formData, {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response)  => {
                setStatus(response.data);
            })
            .catch((error) => {
                alert(error);
            });
    }

    return (
        <div id="Attendance">
            <form className="attendance-form">
                <h1 className="attendance-title">출석 인증 페이지</h1>
                <label htmlFor="username">닉네임</label>
                <input className="input-field" type="text" id="username" value={username}
                       onChange={(e) => setUsername(e.target.value)} required/>
                <br />
                <label htmlFor="code">암구호</label>
                <input className="input-field" type="text" id="code" value={code} onChange={(e) => {
                    setCode(e.target.value)
                }} required/>
                <button className="attendance-button" onClick={onClickAttendance}>인증하기</button>
            </form>
            <h>{status}</h>
        </div>
    )

}

export default Attendance;