const movePictogram = (event) => {
  const svg = document.getElementById('pictogram');
  const rect = svg.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;

  const basic = document.getElementById('basicIntro');
  const novice = document.getElementById('noviceIntro');
  const intermediate = document.getElementById('intermediateIntro');

  if (isPointInsidePolygon(x, y, [250, 0, 500, 144, 250, 288, 0, 144])) {
    if (basic.style.display === "none") {
      basic.style.display = "flex";
      novice.style.display = "none";
      intermediate.style.display = "none";
      document.getElementById('basic').style.fill = "#000a47";
      document.getElementById('novice').style.fill = "#0083cc";
      document.getElementById('intermediate').style.fill = "#0083cc";
    }

    else {
      basic.style.display = "none";
      novice.style.display = "none";
      intermediate.style.display = "none";
      document.getElementById('basic').style.fill = "#0083cc";
      document.getElementById('novice').style.fill = "#0083cc";
      document.getElementById('intermediate').style.fill = "#0083cc";
    }
  }

  else if (isPointInsidePolygon(x, y, [500, 144, 500, 432, 250, 576, 250, 288])) {
    if (novice.style.display === "none") {
      basic.style.display = "none";
      novice.style.display = "flex";
      intermediate.style.display = "none";
      document.getElementById('novice').style.fill = "#000a47";
      document.getElementById('basic').style.fill = "#0083cc";
      document.getElementById('intermediate').style.fill = "#0083cc";
    }

    else {
      basic.style.display = "none";
      novice.style.display = "none";
      intermediate.style.display = "none";
      document.getElementById('basic').style.fill = "#0083cc";
      document.getElementById('novice').style.fill = "#0083cc";
      document.getElementById('intermediate').style.fill = "#0083cc";
    }
  }

  else if (isPointInsidePolygon(x, y, [0, 144, 250, 288, 250, 576, 0, 432])) {
    if (intermediate.style.display === "none") {
      basic.style.display = "none";
      novice.style.display = "none";
      intermediate.style.display = "flex";
      document.getElementById('intermediate').style.fill = "#000a47";
      document.getElementById('novice').style.fill = "#0083cc";
      document.getElementById('basic').style.fill = "#0083cc";
    }

    else {
      basic.style.display = "none";
      novice.style.display = "none";
      intermediate.style.display = "none";
      document.getElementById('basic').style.fill = "#0083cc";
      document.getElementById('novice').style.fill = "#0083cc";
      document.getElementById('intermediate').style.fill = "#0083cc";
    }
  }
};

const isPointInsidePolygon = (x, y, polygonPoints) => {
  let windingNumber = 0;

  for (let i = 0; i < polygonPoints.length; i += 2) {
    const xi = polygonPoints[i];
    const yi = polygonPoints[i + 1];

    const nextIndex = (i + 2) % polygonPoints.length;
    const xj = polygonPoints[nextIndex];
    const yj = polygonPoints[nextIndex + 1];

    if (yi <= y) {
      if (yj > y && isLeftTurn(xi, yi, xj, yj, x, y)) {
        windingNumber++;
      }
    } else {
      if (yj <= y && isRightTurn(xi, yi, xj, yj, x, y)) {
        windingNumber--;
      }
    }
  }

  return windingNumber !== 0;
};

const isLeftTurn = (x0, y0, x1, y1, x, y) => {
  return (x1 - x0) * (y - y0) - (x - x0) * (y1 - y0) > 0;
};

const isRightTurn = (x0, y0, x1, y1, x, y) => {
  return (x1 - x0) * (y - y0) - (x - x0) * (y1 - y0) < 0;
};

export default movePictogram;