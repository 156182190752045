import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const signUpHandle = (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }

        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        axios.post("http://tolelom.xyz:8080/user/signup", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if (response.status === 200) {
                    alert("회원가입 성공!");
                    navigate("/");
                }
                else {
                    alert("회원가입 실패. \n" + response.data);
                }
            })
            .catch (error => {
                alert(error.response.data);
            });
    }


    return (
        <div id="Signup">
            <form className="signup-form">
                <h1 className="signup-title">회원가입</h1>
                <label htmlFor="username">아이디</label>
                <input className="input-field" type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} autoFocus required />
                <br/>
                <label htmlFor="password">비밀번호</label>
                <input className="input-field" type="password" id="password" value={password} onChange={(e) =>  setPassword(e.target.value)} required />
                <br/>
                <label htmlFor="confirmPassword">비밀번호 확인</label>
                <input className="input-field" type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                <br/>
                <button className="signup-button" type="submit" onClick={signUpHandle}>회원가입</button>
            </form>
        </div>
    )
}

export default SignUp;