import React from "react";
import "../css/Contacts.css";

const Contacts = () => {
  return (
    <div className="Contacts">
      <div id="Links">
        <a>문의처</a>
        <a>hiarc.official@gmail.com</a>
        <a href="https://www.instagram.com/hiarc.official/">인스타그램</a>
        <a href="https://pf.kakao.com/_SArxlxb">카카오톡</a>
      </div>
      <div id="Copyrights">@2024HI-ARC</div>
    </div>
  );
};

export default Contacts;
