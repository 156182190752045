import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import "../css/page-specific/UserInfo.css";
import UserInfoContext from "../contexts/userInfoContext";

const UserInfo = () => {
    const [username, setUsername] = useState("Unknown");
    const [studies, setStudies] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);



    useEffect(() => {
        axios.get("http://tolelom.xyz:8080/user/username", {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("token")},
        })
            .then(response => {
                if (response.status === 200) {
                    setUsername(response.data);
                }
                else {
                    alert("유저 정보를 불러올 수 없습니다.\n");
                }
            })
            .catch (error => {
                alert(error);
            });

        axios.get(`http://tolelom.xyz:8080/getmystudy`, {
            headers: { 'Authorization': "Bearer " + localStorage.getItem("token")},
        })
            .then(response => {
                setStudies(response.data);
            })
            .catch(error => {
                alert(error);
            })


        axios.get(`http://tolelom.xyz:8080/getmyattendance`, {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
            }
        })
            .then((response) => {
                setAttendanceData(response.data);
            })
            .catch(error => {

            })
    }, [])





    return (
        <div id="UserInfo">
            <div className="infomation">
                <h1>닉네임: {username}</h1>
                <div>
                    <h2>수강 중인 스터디: {studies}</h2>
                </div>

                <br/>

                <div>
                    <h1>출석 정보</h1>
                    <table>
                        <thead>
                        <tr>
                            <th>스터디</th>
                            <th>주차</th>
                            <th>출석여부</th>
                        </tr>
                        </thead>
                        <tbody>
                        {attendanceData.map(attendance => (
                            <tr key={attendance.id}>
                                <td>{attendance.studyName}</td>
                                <td>{attendance.attendanceName}</td>
                                <td>{attendance.attended ? 'Yes' : 'No'}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default UserInfo;
