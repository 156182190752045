import React from "react";
import movePictogram from "../assets/pictogram";

const Internal = () => {
    return (
        <div className="wrapper">
            <div className="container">
                <svg
                    id="pictogram"
                    width="500px"
                    height="580px"
                    onClick={(event) => movePictogram(event)}
                >
                    <path className="rhombus" id="basic"
                        d="M 250 0
                    L 500 144
                    L 250 288
                    L 0 144
                    L 250 0"
                    />

                    <path className="rhombus" id="novice"
                        d="M 250 288
                    L 500 144
                    L 500 432
                    L 250 576"
                    />

                    <path className="rhombus" id="intermediate"
                        d="M 250 288
                    L 250 576
                    L 0 432
                    L 0 144
                    L 250 288"
                    />
                </svg>
                <div className="text" id="basicIntro">
                    <p>초급 알고리즘 스터디에서는 알고리즘을 이용한 문제 풀이인 PS(Problem Solving)를 처음 접하는 분들을 위한 기본적인 알고리즘과 STL을 배웁니다.
                        매 주 한 가지 알고리즘을 학습하고 기초 수준의 알고리즘 문제를 풀어봅니다.</p>
                </div>
                <div className="text" id="noviceIntro">
                    <p>기초 알고리즘 스터디에서는 기본적인 알고리즘을 배우신 분들을 대상으로 심화된 문제 풀이 테크닉과 정수론 같은 이론적 기반을 배웁니다.
                        매 주 한 가지 알고리즘을 학습하고 초급 수준의 알고리즘 문제를 풀어봅니다.</p>
                </div>
                <div className="text" id="intermediateIntro">
                    <p>중급 스터디에서는 PS에 어느 정도 경험이 있는 분들을 위한 프로그래밍 대회에서 자주 출제되는 테크닉을 포함하여 더 복잡한 알고리즘 기법들을 배웁니다.
                        매 주 한 가지 알고리즘을 학습하고 중급 수준의 알고리즘 문제를 풀어봅니다.</p>
                </div>
            </div>
        </div>
    )
}

export default Internal;