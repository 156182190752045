import React, { useState, useEffect } from "react";
import axios from "axios";


const StudyManager = () => {
    const [testStudyNames, setTestStudyNames] = useState("");
    const [studyName, setStudyName] = useState("");

    const [_studyName, set_StudyName] = useState("");
    const [username, setUsername] = useState("");

    const [__studyName, set__StudyName] = useState("")
    const [attendanceName, setAttendanceName] = useState("");
    const [code, setCode] = useState("");
    const [expiredTime, setExpiredTime] = useState("");

    useEffect(() => {
        getStudyList();
    }, []);

    const getStudyList = () => {
        axios.get("http://tolelom.xyz:8080/getstudy", {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
            }
        })
            .then((response) => {
                setTestStudyNames(response.data);
            })
            .catch((error) => {
                alert(error);
            })
    }

    const onClickMakeStudy = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("name", studyName);

        axios.post("http://tolelom.xyz:8080/makestudy", formData, {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                alert(response.data);
            })
            .catch((error) => {
                alert(error);
            });

    }

    const onClickAddMember = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("name", _studyName);
        formData.append("username", username);

        axios.post("http://tolelom.xyz:8080/addstudymember", formData, {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response)  => {
                alert(response.data);
            })
            .catch((error) => {
                alert(error);
            });
    }


    const onClickSetCode = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("name", __studyName);
        formData.append("attendanceName", attendanceName);
        formData.append("code", code);
        formData.append("expiredTime", expiredTime);

        axios.post("http://tolelom.xyz:8080/addAttendanceList", formData, {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response)  => {
                alert(response.data);
            })
            .catch((error) => {
                alert(error);
            });
    }

    return (
        <div>
            <h1>스터디 관리 페이지</h1>

            <br/>

            <div>
                <h1>개설된 스터디 목록</h1>
                <h2>{testStudyNames}</h2>
                <button onClick={() => getStudyList()}>다시 불러오기</button>
            </div>

            <br />

            <div>
                <form>
                    <h2>새 스터디 추가하기</h2>
                    <label htmlFor="studyname">스터디명: </label>
                    <input type="text" id="studyname" value = {studyName} onChange={(e) => setStudyName(e.target.value)} required/>
                    <button onClick={onClickMakeStudy}>추가하기</button>
                </form>
                <br/>
                <form>
                    <h2>스터디에 스터디원 추가하기</h2>
                    <label htmlFor="_studyname">스터디명: </label>
                    <input type="text" id="_studyname" value={_studyName} onChange={(e) => set_StudyName(e.target.value)}
                           required/>
                    <label htmlFor="username">닉네임: </label>
                    <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)}
                           required/>
                    <button onClick={onClickAddMember}>추가하기</button>
                </form>
                <br/>
                <form>
                    <h2>스터디에 새 출석 코드 만들기</h2>
                    <label htmlFor="__studyname">스터디명: </label>
                    <input type="text" id="__studyname" value={__studyName} onChange={(e) => set__StudyName(e.target.value)}
                           required/>
                    <label htmlFor="attendanceName">출석 주차(이름): </label>
                    <input type="text" id="attendanceName" value={attendanceName} onChange={(e) => setAttendanceName(e.target.value)} required/>
                    <label htmlFor="code">코드: </label>
                    <input type="text" id="code" value={code} onChange={(e) => setCode(e.target.value)} required />
                    <label htmlFor="expiredTime">만료 시간(분): </label>
                    <input type="text" id="expiredTime" value={expiredTime} onChange={(e) => setExpiredTime(e.target.value)} required />
                    <button onClick={onClickSetCode}>등록하기</button>
                </form>
            </div>
        </div>
    )
}

export default StudyManager;