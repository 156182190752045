// routes.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Study from './pages/Study';
import Internal from './pages/Internal';
import Records from './pages/Records';
import StudyManager from "./pages/StudyManager";
import UserInfo from "./pages/UserInfo";
import Attendance from "./pages/Attendance";

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/study" element={<Study />} />
                <Route path="/internal" element={<Internal />} />
                <Route path="/records" element={<Records />} />
                <Route path="/userinfo" element={<UserInfo />} />
                <Route path="/studymanager" element={<StudyManager />} />
                <Route path="/attendance" element={<Attendance />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
