import React from "react";
import AppRoutes from "./Router";
import Navbar from "./contexts/Navbar";
import Contacts from "./contexts/Contacts"

function App() {
  return (
    <div className="App">
      <Navbar />
      <AppRoutes />
      <Contacts />
    </div>
  );
}

export default App;
