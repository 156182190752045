import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import axios from "axios";

const SignIn = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const signInHandle = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        axios.post("http://tolelom.xyz:8080/user/signin", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if (response.status === 200) {
                    const authHeader = response.headers.get("Authorization");
                    const token = authHeader && authHeader.split(' ')[1];
                    localStorage.setItem('token', token);
                    alert("로그인 성공!");
                    navigate("/");
                }
                else {
                    alert("로그인 실패" + response.data);
                }
            })
            .catch(error => {
                alert("로그인 실패" + error.response.data);
            });
    }


    return (
        <div id="Signin">
            <form className="login-form">
                <h1 className="login-title">로그인</h1>
                <label htmlFor="username">아이디</label>
                <input className="input-field" type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} autoFocus required />
                <br/>
                <label htmlFor="password">비밀번호</label>
                <input className="input-field" type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                <br/>
                <button className="login-button" type="submit" onClick={signInHandle}>로그인</button>
            </form>
        </div>
    )
}

export default SignIn;