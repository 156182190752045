import React from "react";
import "../css/page-specific/Home.css";
import logo from "../assets/img/logo_HD.png";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

const Home = () => {
  return (
    <div id="Home">
      <title>HI-ARC</title>
      <Desktop>
        <div className="desktop-wrapper">
          <div className="block" id="block1">
            <p>HI-ARC</p>
          </div>
          <div className="block" id="block2">
            solve
            <a href="">소개</a>
          </div>
          <div className="block" id="block3">
            with
            <a href="">스터디</a>
          </div>
          <div className="block" id="block4">
            us
            <a href="">학회 활동</a>
          </div>
        </div>
      </Desktop>

      <Tablet>
        <div className="tablet-wrapper">
          <img id="logo" src={logo} alt="logo" className="centered-image" />
          <div className="slogan">
            <h1>Solve with Us!</h1>
            <h2>HI-ARC는 알고리즘 학회입니다.</h2>
          </div>
        </div>
      </Tablet>

      <Mobile>
        <div className="mobile-wrapper">
          <img id="logo" src={logo} alt="logo" className="centered-image" />
          <div className="slogan">
            <h1>Solve with Us!</h1>
            <h2>HI-ARC는 알고리즘 학회입니다.</h2>
          </div>
        </div>
      </Mobile>
    </div>
  );
};

export default Home;
