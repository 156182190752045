import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';

const Records = () => {
    const [sheetName, setMyString] = useState('/data/ICPC.csv');

    const handleClick = (string) => {
        setMyString(string);
    }

    const [data, setData] = useState([]);

    useEffect(() => {
        // Fetch the CSV data
        const fetchData = async () => {
            try {
                const response = await axios.get(sheetName);

                // Parse CSV data
                const result = Papa.parse(response.data, { header: true });
                setData(result.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [sheetName]); // Empty dependency array to run the effect only once

    return (
        <div id="Records">
            <h1>Records</h1>
            <h2>HI-ARC는 2017년 창립 이후 매년 교내 프로그래밍 경진대회 및 UCPC와 같은 교외 프로그래밍 대회에도 꾸준히 참가하여 우수한 성적을 거두고 있습니다.<br />
                또한 2020년부터 신촌지역 4개 학교와 연합을 맺어 자체적으로도 프로그래밍 대회를 진행하고 있습니다.</h2>
            <div className='container'>
                <div id="buttons">
                    <div id="ICPC" className='button' onClick={() => handleClick('/data/ICPC.csv')}>ICPC</div>
                    <div id="SUAPC" className='button' onClick={() => handleClick('/data/SUAPC.csv')}>SUAPC</div>
                    <div id="UCPC" className='button' onClick={() => handleClick('/data/UCPC.csv')}>UCPC</div>
                    <div id="school" className='button' onClick={() => handleClick('/data/School.csv')}>교내 대회</div>
                    <div id="etc" className='button' onClick={() => handleClick('/data/etc.csv')}>기타</div>
                </div>
                <div id="table">
                    <table>
                        <thead>
                            {data.length > 0 && (
                                <tr>
                                    {Object.keys(data[0]).map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            )}
                        </thead>
                        <tbody>
                            {data.length > 0 &&
                                data.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {Object.values(row).map((cell, cellIndex) => (
                                            <td key={cellIndex}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Records;
