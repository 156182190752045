import React from "react";
import "../css/Navbar.css";

const Navbar = () => {
  return (
      <div className="Navigation_bar">
        <div id="description">홍익대학교 알고리즘 학회</div>
        <div id="links">
          <a href="/">홈</a>
          <a href="/Login">로그인</a>
          <a href="/SignIn">출석인증</a>
        </div>
      </div>
  );
};

export default Navbar;
